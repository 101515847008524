
import { Component, Vue } from 'vue-property-decorator';
import client from '@/shared/plugins/content';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import StarRating from 'vue-star-rating';
import { Loading } from '../modules/auth/store/auth.service';

// Vue.component('star-rating', StarRating.default);

@Component({
  layout: 'default',
  metaInfo: {
    title: 'Check-in confirmation',
    // override the parent template and just use the above title only
  },
  // components: {
  //   StarRating
  // },
})
export default class CheckinConfirmation extends Vue {
  // rating = 0;

  // feedback = '';

  // buttonText = 'Submit';

  // title = '';

  // terms = '';

  get background() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/wave-grey.webp');
  }

  // //Setting Star rating and submitting
  // setRating(rating : number, feedback: string) {
  //   this.rating= rating;
  //   this.feedback = feedback;
  // }

  // //Need to talk to Nick about where to send this data!
  // @Loading
  // submit(rating :number, feeback: string) {
  //   console.log(rating);
  //   console.log(feeback);
  //   this.buttonText = 'Thank you!';
  // }
}

// bleh

